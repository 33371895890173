<template>
  <div class="content-toggle">
    <slot v-if="!showAlt" />
    <div v-if="hasSwitched" v-show="showAlt" class="content-toggle__slot">
      <template v-if="showAlt || hasSwitched">
        <slot name="alt" />
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch } from "vue";

const props = defineProps<{ showAlt: boolean }>();

const hasSwitched = ref(props.showAlt);

if (!props.showAlt) {
  const killWatch = watch(
    () => props.showAlt,
    (showAlt) => {
      if (showAlt) {
        hasSwitched.value = true;
        killWatch();
      }
    }
  );
}
</script>

<style lang="scss" scoped>
.content-toggle {
  display: flex;
  height: 100%;
  &__slot {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
  }
}
</style>
